.MD_SUPPORT_TICKET_CONTENT {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;
  min-height: 200px;
  height: 300px;
  max-height: 300px;
  padding-top: 2em;
  transition: all 0.5s ease-in-out;
  background-color: var(--background1);
  overflow: auto;
}

.MD_SUPPORT_TICKET_CREATOR {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: 1em;
  margin-top: 1em;
  margin-bottom: 2em;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 50%;
  max-width: 80%;
  min-height: max-content;
  height: max-content;
  border-radius: 10px;
  padding-bottom: 1em;
  padding: 0.2em;
  background-color: var(--user-reply-bg);
  color: var(--secondary-font-color);
}

.MD_SUPPORT_TICKET_ASSESSTANCE {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 1em;
  margin-top: 1em;
  margin-bottom: 2em;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 50%;
  max-width: 80%;
  min-height: max-content;
  height: max-content;
  border-radius: 10px;
  padding-bottom: 1em;
  padding: 0.2em;
  background-color: var(--assistance-reply-bg);
  color: var(--secondary-font-color);
}

.MD_SUPPORT_TICKET_DATA {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
}
