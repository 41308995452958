.SELECT_CONTROLLER_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 95vh;
  height: max-content;
  background-color: var(--background);
}

.SELECT_CONTROLLER_HEADER_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
  color: var(--font-color);
  padding: 2em;
  font-family: Roboto;
}

.CONTROLLERS_MAIN_CONTAINER {
  display: grid;
  grid-template-columns: repeat(2, 300px);
  align-items: center;
  justify-content: center;
  gap: 4em;
  width: 100%;
  min-height: 400px;
  height: 400px;
  margin-top: 30px;
  transition: all 0.5s ease-in;
}

.AVAILABLE_CONTROLLER_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  min-width: 300px;
  height: 300px;
  min-height: 300px;
  border-radius: 50px;
  background: var(--background);
  box-shadow: 15px 15px 30px var(--shade-1), -15px -15px 30px var(--shade-2);
  transition: all 0.2s ease-in;
}

.AVAILABLE_CONTROLLER_CONTAINER:hover {
  cursor: pointer;
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
}

.AVAILABLE_CONTROLLER_LABLE {
  text-align: center;
  font-family: sans-serif;
  color: var(--font-color);
  word-wrap: break-word;
  margin: 0.5em;
}

@media only screen and (max-width: 600px) {
  .CONTROLLERS_MAIN_CONTAINER {
    grid-template-columns: repeat(1, 200px);
    gap: 3em;
    width: 100%;
    min-height: 300px;
    height: max-content;
  }
  .AVAILABLE_CONTROLLER_CONTAINER {
    width: 200px;
    min-width: 200px;
    height: 200px;
    min-height: 200px;
    background: var(--background);
    box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
  }
  .AVAILABLE_CONTROLLER_LABLE {
    font-size: 0.8rem;
    text-align: center;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
}
