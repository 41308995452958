.ANALYTICS_HEADER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  height: 50px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 2em;
  transition: all 1s ease-in-out;
}
.PREV_MONTH {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
  color: var(--font-color);
  transition: all 1s ease-in-out;
}
.PREV_MONTH:hover {
  cursor: pointer;
}
.PREV_MONTH > span {
  font-family: sans-serif;
  font-size: 1.2rem;
  color: var(--secondary-font-color);
}
.ACTIVE_MONTH {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
}
.ACTIVE_MONTH > h3 {
  font-family: sans-serif;
  color: var(--font-color);
}
.NEXT_MONTH {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
  color: var(--font-color);
  transition: all 1s ease-in-out;
}

.NEXT_MONTH:hover {
  cursor: pointer;
}

.NEXT_MONTH > span {
  font-family: sans-serif;
  font-size: 1.2rem;
  /* margin-bottom: 0.2em; */
  color: var(--secondary-font-color);
}
