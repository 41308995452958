.CONTROL_DASHBOARD_GENERAL_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.CONTROL_DASHBOARD_GENERAL_GRAPHS_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
  min-height: 350px;
  max-height: max-content;
  margin-bottom: 1em;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.CONTROL_DASHBOARD_GENERAL_LINE_CHART_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
  min-height: 320px;
  max-height: max-content;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 2em;
}

.CONTROL_DASHBOARD_GENERAL_PEAK_GRAPH_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  min-height: 350px;
  max-height: max-content;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 100px;
}

.CONTROL_DASHBOARD_GENERAL_LINE_CHART_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 85%;
  min-height: max-content;
  padding: 1em;
  border: 1px solid #222831;
  border-radius: 20px;
}

.CONTROL_DASHBOARD_GENERAL_PEAK_GRAPH_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-width: 1200px; */
  width: 100%;
  height: 90%;
  min-height: max-content;
  padding: 1em;
  border: 1px solid #222831;
  border-radius: 20px;
}

/* Device */
.CONTROL_DASHBOARD_GENERAL_DEVICES_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1050px;
  height: 210px;
  min-height: 200px;
  max-height: max-content;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
  border-radius: 20px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 2em;
}

.CONTROL_DASHBOARD_GENERAL_DEVICES_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  min-height: max-content;
  padding: 1em;
}

.CONTROL_DASHBOARD_GENERAL_DEVICES_DES_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  height: 100%;
  /* background-color: antiquewhite; */
}

.CONTROL_DASHBOARD_GENERAL_DEVICES_DES_CONTAINER h3 {
  font-family: sans-serif;
}
.CONTROL_DASHBOARD_GENERAL_DEVICES_DES_CONTAINER p {
  font-family: Roboto;
}
/* Device */

/* Alerts */
.CONTROL_DASHBOARD_GENERAL_ALERTS_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1050px;
  height: 300px;
  min-height: 300px;
  max-height: max-content;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
  border-radius: 20px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 2em;
}

.CONTROL_DASHBOARD_GENERAL_ALERTS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  min-height: max-content;
  padding: 1em;
}

.CONTROL_DASHBOARD_GENERAL_ALERTS_DES_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;
  height: 200px;
  /* background-color: antiquewhite; */
}

.CONTROL_DASHBOARD_GENERAL_ALERTS_DES_CONTAINER h3 {
  font-family: sans-serif;
  margin-left: 2.5em;
}
.CONTROL_DASHBOARD_GENERAL_ALERTS_DES_CONTAINER p {
  font-family: Roboto;
  margin-left: 3em;
}

.CONTROL_DASHBOARD_GENERAL_ALERTS_GRAPH_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 90%;
}

/* Alerts */

/* Support */
.CONTROL_DASHBOARD_GENERAL_SUPPORT_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1050px;
  height: 300px;
  min-height: 300px;
  max-height: max-content;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
  border-radius: 20px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 2em;
}

.CONTROL_DASHBOARD_GENERAL_SUPPORT_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  min-height: max-content;
  padding: 1em;
}

.CONTROL_DASHBOARD_GENERAL_SUPPORT_DES_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 60%;
  height: 200px;
  /* background-color: antiquewhite; */
}

.CONTROL_DASHBOARD_GENERAL_SUPPORT_DES_CONTAINER h3 {
  font-family: sans-serif;
  margin-left: 2.5em;
}
.CONTROL_DASHBOARD_GENERAL_SUPPORT_DES_CONTAINER p {
  font-family: Roboto;
  margin-left: 3em;
}

.CONTROL_DASHBOARD_GENERAL_SUPPORT_GRAPH_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 90%;
}
/* Support */
