.CONTROL_DASHBOARD_ALERTS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  height: 100%;
}

.CONTROL_DASHBOARD_ALERTS_HEADER_CONTAINER {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 50px;
  min-height: max-content;
  border: 1px solid #c5cdd3;
  border-radius: 25px;
  margin-bottom: 1em;
  background-color: #222831;
}

.CONTROL_DASHBOARD_ALERTS_BODY_CONTAINER {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 1em; */
  width: 100%;
  height: 120px;
  min-height: max-content;
  background-color: #eeeeee;
  border: 1px solid #c5cdd3;
  border-radius: 10px;
}
.CONTROL_DASHBOARD_ALERTS_BODY_CONTAINER > p {
  padding: 0.5em;
}

.CONTROL_DASHBOARD_ALERTS_FOOTER_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 30px;
  min-height: max-content;
}
.CONTROL_DASHBOARD_ALERTS_FOOTER_CONTAINER > span {
  margin-right: 1em;
}

.CONTROL_DASHBOARD_ALERTS_INDICATOR {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  height: 100%;
}
.ALERTS_INDICATOR {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #f05454;
}
.CONTROL_DASHBOARD_ALERTS_TYPE {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
}
.CONTROL_DASHBOARD_ALERTS_DEVICE {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
}
.CONTROL_DASHBOARD_ALERTS_USER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
}
.CONTROL_DASHBOARD_ALERTS_REGION {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
}

.ALTERT_DEVIDER {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #222831;
  margin-block: 1em;
}
