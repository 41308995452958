.CLIENT_PROFILE_USER_CONTROLLER_CONTAINER {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  max-width: 300px;
  min-height: 300px;
  max-height: 300px;
  margin-bottom: 2em;
  border-radius: 30px;
  background: var(--background);
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
  transition: all 0.2s ease-in;
}

.CLIENT_PROFILE_USER_CONTROLLER_IMAGE_CONTAINER {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 50px;
  height: 50px;
}
.CLIENT_PROFILE_USER_CONTROLLER_BUTTON_CONTAINER {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  color: var(--font-color);
  font-size: 1.5rem;
}

.CLIENT_PROFILE_USER_CONTROLLER_IMAGE_CONTAINER:hover {
  cursor: pointer;
}

.CLIENT_PROFILE_USER_CONTROLLER_NAME_CONTAINER h2 {
  font-family: Roboto;
}

.CLIENT_PROFILE_USER_CONTROLLER_INFO_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
}
