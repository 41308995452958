.DASHBOARD_CONTROLLER_CONFIGS_CONTAINER {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  height: 100%;
  border-radius: 20px;
  /* background: linear-gradient(180deg, #1a1a2e 80%, #11233a 100%, #162e4c 100%); */
  background: linear-gradient(145deg, var(--background1), var(--background2));
  box-shadow: 20px 20px 40px var(--shade-1), -20px -20px 40px var(--shade-2);
  transition: all 1s ease-in-out;
}

.DASHBOARD_CONTROLLER_CONFIGS_MODES_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* overflow: auto; */
}

.DASHBOARD_CONTROLLER_CONFIGS_MODES {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 50px;
  width: max-content;
  transition: all 1s ease-in-out;
}

.DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-width: 90px;
}

.DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON_CONTAINER > span {
  font-family: Roboto;
  color: var(--font-color);
  margin-top: 0.5em;
  font-size: 1rem;
  height: 15px;
}

.DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  min-height: 50px;
  width: 50px;
  min-height: max-content;
  margin: 1em;
  color: #fa7d09;
  border-radius: 50px;
  background: linear-gradient(145deg, #5a8bca, #193557);
  box-shadow: 5px 5px 10px var(--shade-1), -5px -5px 10px var(--shade-2);
  transition: all 0.5s ease-in-out;
}

.DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON:hover {
  cursor: pointer;
}

.DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON:active {
  background: #162e4c;
  box-shadow: inset 20px 20px 40px #132741, inset -20px -20px 40px #193557;
}

/* .DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON > h5 {
  font-family: Roboto;
  font-size: 0.9rem;
  color: #060930;
} */

.DASHBOARD_CONTROLLER_TAB_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding-top: 2em;
  transition: all 1s ease-in-out;
}
.DASHBOARD_CONTROLLER_TAB_DES {
  font-family: sans-serif;
  color: var(--font-color);
  margin-top: 0.7em;
  font-weight: bold;
}

.DASHBOARD_CONTROLLER_CONFIGS_SCHEDULE_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  overflow: auto;
  transition: all 1s ease-in-out;
}

@media only screen and (max-width: 600px) {
  .DASHBOARD_CONTROLLER_CONFIGS_CONTAINER {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    min-height: 550px;
    height: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  .DASHBOARD_CONTROLLER_CONFIGS_SCHEDULE_CONTAINER {
    width: 100%;
  }
  .DASHBOARD_CONTROLLER_CONFIGS_MODE_BUTTON_CONTAINER > span {
    margin-top: 0.5em;
    font-size: 0.9rem;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .DASHBOARD_CONTROLLER_CONFIGS_CONTAINER {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 97%;
    height: 500px;
  }
  .DASHBOARD_CONTROLLER_CONFIGS_SCHEDULE_CONTAINER {
    width: 99%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .DASHBOARD_CONTROLLER_CONFIGS_CONTAINER {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .DASHBOARD_CONTROLLER_CONFIGS_SCHEDULE_CONTAINER {
    width: 99%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
}
