.DASHBOARD_CONTROLLER_CONFIGS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1300px;
  min-height: max-content;
  margin-top: 1em;
  margin-bottom: 50px;
  transition: all 1s ease-in-out;
}

.DASHBOARD_CONTROLLER_CONFIGS_WRAPPER_CONTAINER {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 90%;
  max-width: 1300px;
  height: 600px;
  margin-top: 2em;
  padding-inline: 20px;
  transition: all 1s ease-in-out;
}

.DASHBOARD_CONTROLLER_CONFIGS_MAIN_HEADER_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 50px;
  margin-top: 3em;
  margin-bottom: 2em;
  margin-left: 1em;
  margin-right: auto;
  border-radius: 50px;
  background: linear-gradient(
    145deg,
    var(--header-background1),
    var(--header-background2)
  );
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
}

.DASHBOARD_CONTROLLER_CONFIGS_MAIN_HEADER_CONTAINER h2 {
  color: var(--font-color);
  font-family: sans-serif;
  /* line-height: 5px; */
}

.DASHBOARD_CONTROLLER_CONFIGS_MAIN_HEADER_CONTAINER > span {
  color: #6e85b2;
  font-family: sans-serif;
  font-size: 0.9rem;
  line-height: 5px;
}

.DASHBOARD_CONTROLLER_CONFIGS_TEMP_CONTAINER {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  height: 100%;
  padding: 1em;
}

.DASHBOARD_CONTROLLER_CONFIGS_MAX_TEMP_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 1em;
  width: 250px;
  height: 250px;
  border-radius: 40px;
  background: linear-gradient(145deg, var(--background1), var(--background2));
  box-shadow: 15px 15px 30px var(--shade-1), -15px -15px 30px var(--shade-2);
}

.DASHBOARD_CONTROLLER_CONFIGS_MIN_TEMP_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 250px;
  height: 250px;
  border-radius: 40px;
  background: linear-gradient(145deg, var(--background1), var(--background2));
  box-shadow: 15px 15px 30px var(--shade-1), -15px -15px 30px var(--shade-2);
}

.DASHBOARD_CONTROLLER_CONFIGS_SET_TEMP_HEADER {
  margin-block: 0.5em;
  color: var(--font-color);
  font-size: 1.2rem;
  font-weight: bold;
  font-family: Roboto;
}

.DASHBOARD_CONTROLLER_CONFIGS_TEMP_VALUE {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 20%;
  border: 1px solid #1f4068;
  border-radius: 10px;
  background-color: var(--temp-value-bg);
  margin-block: 0.5em;
  color: #d4d4f1;
  font-family: sans-serif;
  font-size: 2rem;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .DASHBOARD_CONTROLLER_CONFIGS_MAIN_HEADER_CONTAINER {
    margin-left: auto;
  }
  .DASHBOARD_CONTROLLER_CONFIGS_MAIN_HEADER_CONTAINER > span {
    font-size: 0.7rem;
  }

  .DASHBOARD_CONTROLLER_CONFIGS_WRAPPER_CONTAINER {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: max-content;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .DASHBOARD_CONTROLLER_CONFIGS_MAIN_CONTAINER {
    align-items: flex-start;
    width: 95%;
    height: max-content;
    margin-top: 100px;
  }
  .DASHBOARD_CONTROLLER_CONFIGS_WRAPPER_CONTAINER {
    flex-direction: column;
    height: max-content;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
}
