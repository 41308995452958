.HISTOGRAM_CHART_INFO_CONTAINER {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: 3px;
  right: 5px;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  z-index: 100;
}

.HISTOGRAM_CHART_MAIN_CONTAINER {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 1em;
  border-radius: 20px;
  /* background: linear-gradient(145deg, var(--background1), var(--background2)); */
  background: var(--background);
  box-shadow: 12px 12px 30px var(--shade-1), -12px -12px 30px var(--shade-2);
}

@media only screen and (max-width: 600px) {
  .HISTOGRAM_CHART_MAIN_CONTAINER {
    width: 80%;
  }
}
