.BAR_CHART_MAIN_CONTAINER {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.ANALYTICS_CHART_WATER_MARK_CONTAINER {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.6;
  background-color: var(--background);
  border-radius: 10px;
}
