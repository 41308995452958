.LINE_CHART_MAIN_CONTAINER {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 1em;
  border-radius: 20px;
  /* background: linear-gradient(145deg, var(--background1), var(--background2)); */
  background: var(--background);
  box-shadow: 12px 12px 30px var(--shade-1), -12px -12px 30px var(--shade-2);
}

.LINE_CHART_INFO_CONTAINER {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  z-index: 100;
}

.TOOLTIP_CONTAINER {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
}
.TOOLTIP_CONTAINER > span {
  height: 20px;
  font-weight: bold;
}
.TOOLTIP_ITEM {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 15px;
}

@media only screen and (max-width: 600px) {
  .LINE_CHART_MAIN_CONTAINER {
    width: 80%;
  }
}
