.RUN_SCHEDULE_TAB_PANEL_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 300px;
  height: 300px;
  overflow: auto;
}

.RUN_SCHEDULE_TAB_PANEL_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 98%;
  height: 350px;
}

.RUN_SCHEDULE_TAB_PANEL_BUTTON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  height: 80px;
  width: 100%;
}

.RUN_SCHEDULE_TAB_PANEL_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;
  margin-bottom: 1.5em;
  width: 50px;
  height: 50px;
  font-size: 2rem;
  color: #f05454;
  border-radius: 50px;
  background: linear-gradient(145deg, var(--background1), var(--background2));
  box-shadow: 5px 5px 15px var(--shade-1), -5px -5px 15px var(--shade-2);
  transition: all 0.3s ease-in-out;
}

.RUN_SCHEDULE_TAB_PANEL_BUTTON:hover {
  cursor: pointer;
  background: linear-gradient(145deg, var(--background1), var(--background2));
  box-shadow: 5px 5px 20px var(--shade-1), -5px -5px 20px var(--shade-2);
}

.RUN_SCHEDULE_TAB_PANEL_BUTTON:active {
  cursor: pointer;
  box-shadow: none;
}
