.CONFIRM_RESET_PASSWORD_PAGE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 150px;
  height: 100vh;
  background-color: #dae1e7;
}

.CONFIRM_RESET_PASSWORD_FORM {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 350px;
}
.CONFIRM_PASSWORD_INPUT_FIELD_FRAME {
  display: flex;
  padding: 10px;
  width: inherit;
}
.CONFIRM_PASSWORD_INPUT_FIELD {
  color: #27496d !important;
  /* background-color: #eeeeee; */
  /* border-radius: 15px !important; */
}

.CONFIRM_PASSWORD_FORM_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 200px !important;
  border-radius: 25px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px;
  background: #152c48;
  /* border: 1px solid #162e4c65; */
  /* box-shadow: 10px 10px 20px #b9bfc4, -10px -10px 20px #fbffff;*/
  transition: all 0.5s ease-in-out;
}
.CONFIRM_PASSWORD_FORM_BUTTON h6 {
  color: #dae1e7;
  font-family: sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
}
.CONFIRM_PASSWORD_FORM_BUTTON h6:hover {
  cursor: pointer;
}

.CONFIRM_PASSWORD_ERROR_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CONFIRM_PASSWORD_ERROR_CONTAINER p {
  font-size: 1rem;
  text-align: center;
  font-family: sans-serif;
  font-weight: 600;
}
