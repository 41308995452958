.VERIFY_ACCOUNT_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 94vh;
  background-color: #dae1e7;
}

.VERIFY_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3em;
}

.VERIFY_ICON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #162e4c;
  font-size: 6rem;
}
.VERIFY_HEADER_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
}
.VERIFICATION_FEEDBACK_MESSAGE {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 0.5em;
  border-radius: 10px;
}
.VERIFY_FORM_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
}
.VERIFY_BUTTON_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
