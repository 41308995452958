.LOGS_TABLE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2em;
  width: 100%;
  max-width: 800px;
  min-height: 200px;
  height: max-content;
}

.LOGS_DAILY_TABLE_DATA {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100px;
  height: max-content;
  width: 100%;
  max-width: 800px;
  margin-top: 2em;
}
.LOGS_DAILY_TABLE_DATA_ITEM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  height: 70px;
  width: 95%;
  border-radius: 10px;
  margin-bottom: 1em;
  background-color: var(--background3);
}

.LOGS_DAILY_TABLE_DATA_ITEM_HEADER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--font-color);
  font-weight: bold;
  font-family: sans-serif;
  width: 90%;
}

.LOGS_DAILY_TABLE_DATA_ITEM > span {
  font-family: sans-serif;
  font-weight: bold;
  margin-left: 1em;
  color: var(--font-color);
  margin-top: 0.3em;
}

.LOGS_DAILY_TABLE_DATA_ITEM_CELLS {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 85%;
}
.LOGS_DAILY_TABLE_DATA_ITEM_CELL {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.LOGS_DAILY_TABLE_DATA_ITEM_CELL > span {
  font-family: sans-serif;
  color: var(--secondary-font-color);
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0.5em;
}

.LOGS_DAILY_TABLE_DATA_ITEM_CELL > p {
  font-family: Roboto;
  color: var(--secondary-font-color);
  font-size: 0.9rem;
}

.NO_LOGS_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-color);
  font-family: sans-serif;
  font-size: 1rem;
}
