.PIE_CHART_INFO_CONTAINER {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  z-index: 100;
  /* background-color: #2c3e50; */
}
