.LOGIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 85vh;
  height: max-content;
  padding-top: 100px;
  background-color: #dae1e7;
}

.LOGIN_FORM_ICON {
  color: #10101e;
  font-size: 8rem;
}

.form-signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.LOGIN_INPUT_FIELD {
  color: #10101e !important;
  border-radius: 10px !important;
  height: 50px;
}

.LOGIN_INPUT_FIELD_LABEL {
  color: #10101e !important;
  font-family: monospace !important;
  font-size: 0.85rem !important;
}

.LOGIN_BUTTON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 20px;
  height: 45px;
  width: 200px !important;
  border-radius: 10px;
  color: #dae1e7;
  background: #152c48;
  transition: all 0.5s ease-in-out;
}

.LOGIN_BUTTON_CONTAINER h6 {
  font-size: 1rem;
  font-family: sans-serif;
  color: #1c2028;
}
.LOGIN_BUTTON_CONTAINER:hover {
  cursor: pointer;
  background: #15345a;
  transform: scale(1.05);
}

.FORGOT_PASSWORD_CONTAINER h6 {
  color: rgb(235, 114, 114) !important;
}

.FORGOT_PASSWORD_CONTAINER h6 {
  font-family: monospace;
  font-size: 1.2rem;
  font-weight: 500;
  color: #dae1e7;
}
.FORGOT_PASSWORD_CONTAINER h6:hover {
  cursor: pointer;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .form-signin {
    width: 80vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .form-signin {
    width: 80vw;
  }

  .login_container {
    align-content: center !important;
    justify-content: center !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .login_container {
    align-content: center !important;
    justify-content: center !important;
  }
  .form-signin {
    width: 500px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .form-signin {
    width: 500px;
  }

  .login_container {
    float: right;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .form-signin {
    width: 500px;
  }
  .login_container {
    float: right;
  }
}
