.SUPPORT_PREVIEW_TICKET_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 98%;
  height: 100%;
  color: var(--font-color);
  /* overflow: auto; */
}

.SUPPORT_PREVIEW_TICKET_HEADER_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 50px;
}

.SUPPORT_PREVIEW_TICKET_HEADER_CONTAINER > span {
  margin-left: 20px;
}

.SUPPORT_PREVIEW_TICKET_CONTENT_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: max-content;
  /* background-color: #333; */
}

.SUPPORT_PREVIEW_TICKET_REPLY_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1em;
  min-height: 200px;
  max-height: 300px;
}

.SUPPORT_SUBMIT_REPLY_BODY {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin-top: 1em;
}

.CONTROL_DASHBOARD_SUBMIT_REPLY_BUTTON {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  margin-top: 1em;
}

.SUPPORT_PREVIEW_TICKET_REPLYS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 200px;
  max-height: max-content;
}

.SUPPORT_TICKET_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  height: max-content;
  min-height: 200px;
  margin-bottom: 2em;
}

.SUPPORT_TICKET_INFO {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 260px;
  height: 100%;
  margin-right: 1em;
}

.SUPPORT_TICKET_INFO > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  height: 40px;
}

.SUPPORT_TICKET_INFO .CONTENT_TICKET_INFO_KEY {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
}

.SUPPORT_TICKET_INFO .CONTENT_TICKET_INFO_VALUE {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
}

.SUPPORT_TICKET_CONTENT {
  display: flex;
  width: 68%;
  height: 100%;
}
.SUPPORT_TICKET_CONTENT > p {
  padding: 1em;
}

@media only screen and (max-width: 600px) {
  .SUPPORT_TICKET_CONTAINER {
    flex-direction: column-reverse;
  }
  .SUPPORT_TICKET_INFO {
    width: 90%;
    margin-right: 0em;
    margin-bottom: 1em;
  }
  .SUPPORT_TICKET_CONTENT {
    width: 90%;
    margin-bottom: 1em;
  }
}
