.CONTROL_DASHBOARD_CONTENT_SUPPORT_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.CONTROL_DASHBOARD_CONTENT_PREVIOUS_TICKETS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: max-content;
  max-height: 300px;
  margin-bottom: 40px;
}

.CONTROL_DASHBOARD_CONTENT_PREVIEW_TICKET_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: max-content;
  max-height: 500px;
  margin-bottom: 40px;
}

.CONTROL_DASHBOARD_CONTENT_SUBMIT_TICKET_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 400px;
  background-color: #eeeeee;
  border-radius: 20px;
  /* box-shadow: 0 1px 3px #333; */
}
.CONTROL_DASHBOARD_CONTENT_SUBMIT_TICKET_LABLE {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 98%;
  height: 60px;
  padding-inline: 1em;
  margin-bottom: 1em;
}
.CONTROL_DASHBOARD_CONTENT_SUBMIT_TICKET_LABLE > span {
  font-family: sans-serif;
  font-weight: 400;
}

.CONTROL_DASHBOARD_CONTENT_SUBMIT_TICKET_HEADER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  height: 80px;
  min-height: max-content;
}

.CONTROL_DASHBOARD_SUBMIT_TICKET_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.CONTROL_DASHBOARD_CONTENT_SUBMIT_TICKET_BODY {
  display: flex;
  width: 94%;
  height: 200px;
  min-height: max-content;
  margin-top: 1em;
}
.CONTROL_DASHBOARD_CONTENT_SUBMIT_TICKET_FOOTER {
  display: flex;
  width: 94%;
  height: 60px;
  min-height: 50px;
}
