#dark {
  --background: #162e4c;
  --listItemColor: #bbbbbb;
  --background1: #162e4c;
  --background2: #183151;
  --shade-1: #132741;
  --shade-2: #193557;
}

#light {
  --background: #dddddd;
  --listItemColor: #162e4c;
  --background1: #ececec;
  --background2: #c7c7c7;
  --shade-1: #bcbcbc;
  --shade-2: #fefefe;
}

.DRAWER_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.DRAWER_HEADER_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background-color: var(--background);
}

.DRAWER_HEADER_PROFILE_AVATAR {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background-color: var(--background);
  background: linear-gradient(145deg, var(--background1), var(--background2));
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
}

.DRAWER_HEADER_PROFILE_AVATAR:hover {
  cursor: pointer;
}

.DRAWER_LIST_CONTAINER {
  background-color: var(--background);
  height: 100%;
}

.DRAWER_LIST_ITEM {
  color: var(--listItemColor);
}

.DRAWER_SECOND_LIST_CONTAINER {
  display: flex;
  width: 100%;
  background-color: var(--background);
}
