.LOGS_DATE_PICKERS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 720px;
  height: 60px;
  min-height: 60px;
  background-color: var(--background4);
  border-radius: 10px;
  padding: 0.5em;
  transition: all 0.5s ease-in-out;
}
.LOGS_DATE_PICKER_INPUT_FIELD {
  max-width: 40%;
  margin: 1em !important;
  border-radius: 5px;
}

@media only screen and (max-width: 600px) {
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
}
