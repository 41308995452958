.TERMS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--background);
}

.TERMS_HEADER_CONTAINER {
  display: flex;
  padding: 2em;
  font-family: sans-serif;
  color: var(--font-color);
}

.TERMS_CONTENT_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  max-width: 1100px;
  color: var(--font-color);
  margin: 2em;
}
