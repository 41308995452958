.CLIENT_PROFILE_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  padding-top: 60px;
  background: var(--background);
}

.CLIENT_PROFILE_SAVE_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 75px;
  right: 5%;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  color: var(--font-color);
  font-size: 1.5rem;
  z-index: 10;
  background: var(--background);
  box-shadow: 5px 5px 10px var(--shade-1), -5px -5px 10px var(--shade-2);
  transition: all 0.2s ease-in;
}
.CLIENT_PROFILE_SAVE_BUTTON:hover {
  cursor: pointer;
}

.CLIENT_PROFILE_HEADER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1100px;
  min-height: 50px;
  height: 60px;
}
.CLIENT_PROFILE_HEADER_TEXT {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-family: sans-serif;
  color: var(--font-color);
}

.CLIENT_PROFILE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100%;
  height: max-content;
}

.CLIENT_PROFILE_USER_INFO_MAIN_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  min-height: 220px;
  height: max-content;
  margin-top: 2em;
  margin-bottom: 1em;
}
.CLIENT_PROFILE_USER_INF0_HEADER {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  max-width: 1080px;
  font-family: Roboto;
  color: var(--secondary-font-color);
}

.CLIENT_PROFILE_USER_INFO_DATA_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 40%;
  padding: 1em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  border-radius: 30px;
  background: var(--background);
  box-shadow: 15px 15px 30px var(--shade-1), -15px -15px 30px var(--shade-2);
  transition: all 0.2s ease-in;
}
.USER_INPUT_FIELD_CONTAINER {
  display: flex;
  padding: 0.5em;
  width: 95%;
}
.USER_PROFILE_FORM_INPUT_FIELD {
  color: var(--font-color) !important;
}
.USER_PROFILE_FORM_INPUT_FIELD_LABEL {
  color: var(--field-lable) !important;
}
.CLIENT_PROFILE_USER_INFO_ACTION_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 40%;
  padding: 1em;
  border-radius: 30px;
  background: var(--background);
  box-shadow: 15px 15px 30px var(--shade-1), -15px -15px 30px var(--shade-2);
  transition: all 0.2s ease-in;
}
.CLIENT_PROFILE_USER_CONTROLLERS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  min-height: 320px;
  margin-bottom: 2em;
}

.CLIENT_PROFILE_USER_CONTROLLERS_HEADER {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding-left: 5%;
  font-family: Roboto;
  margin-top: 1em;
  color: var(--secondary-font-color);
}

.CLIENT_PROFILE_USER_CONTROLLERS_CONTAINER {
  display: grid;
  gap: 1.5em;
  grid-template-columns: repeat(3, 310px);
  padding: 1rem;
  margin: auto;
  width: max-content;
  max-width: 1200px;
  width: 92%;
  min-height: 300px;
  padding-top: 2em;
  border-radius: 20px;
  overflow: auto;
}

.CLIENT_PROFILE_ADD_CONTROLLER_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  max-width: 300px;
  min-height: 200px;
  height: 300px;
  color: var(--font-color);
  font-size: 5rem;
  border-radius: 20px;
}

.CLIENT_PROFILE_USER_SUPPORT_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1120px;
  min-height: 250px;
  height: max-content;
  background-color: var(--background);
  border-radius: 30px;
  background: var(--background);
  box-shadow: 15px 15px 30px var(--shade-1), -15px -15px 30px var(--shade-2);
  transition: all 0.2s ease-in;
  margin-bottom: 2em;
}

@media only screen and (max-width: 600px) {
  .CLIENT_PROFILE_MAIN_CONTAINER {
    display: flex;
    width: 100vw;
    height: max-content;
    padding-top: 60px;
  }
  .CLIENT_PROFILE_USER_INFO_MAIN_CONTAINER {
    flex-direction: column;
    min-width: 300px;
    width: 95%;
    min-height: 400px;
  }

  .CLIENT_PROFILE_CONTAINER {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
  }
  .CLIENT_PROFILE_USER_INFO_DATA_CONTAINER {
    width: 90%;
    margin-bottom: 3em;
  }
  .CLIENT_PROFILE_USER_INFO_ACTION_CONTAINER {
    width: 90%;
  }

  .ADD_NEW_CONTROLLER_MODAL {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    min-height: 350px;
    background-color: var(--background);
    border-radius: 20px;
    box-shadow: 1px 1px 10px #2c3e50;
    padding: 20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
}
