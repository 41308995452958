.DASHBOARD_SIMPLE_TIME_SLOT_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  width: 100%;
  min-height: 50px;
  min-width: 310px;
  margin: 0.5em;
  padding: 0.1em;
  border-radius: 10px;
}

.DASHBOARD_SIMPLE_TIME_SLOT_TIME {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35%;
  height: 100%;
  padding: 0.1em;
  border-radius: 10px;
  background-color: var(--header-background1);
  transition: all 0.5s ease-in-out;
}

.DASHBOARD_SIMPLE_TIME_SLOT_TIME > span {
  font-size: small;
  font-family: Roboto;
  color: var(--font-color);
}

.DASHBOARD_SIMPLE_TIME_SLOT_BODY {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
  padding: 0.1em;
  border-radius: 10px;
  background-color: var(--header-background1);
  transition: all 0.5s ease-in-out;
}

.DASHBOARD_SIMPLE_TIME_SLOT_BODY > p {
  font-family: sans-serif;
  color: var(--font-color);
}

.DASHBOARD_SIMPLE_TIME_SLOT_BODY:hover {
  cursor: pointer;
}
