.USER_SUPPORT_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  background-color: var(--background);
}

.USER_SUPPORT_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  height: max-content;
  margin-top: 1em;
}

.USER_PREVIOUS_TICKETS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  height: max-content;
  max-height: 400px;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 10px;
  overflow: auto;
  background: var(--background);
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
  transition: all 0.1s ease-in;
}

.USER_SUPPORT_HEADER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
  height: 70px;
  margin: 1em;
}

.USER_SUPPORT_HEADER > h2 {
  font-family: sans-serif;
  color: var(--font-color);
  height: 10px;
}

.USER_SUPPORT_HEADER > span {
  font-family: Roboto;
  color: var(--secondary-font-color);
}

.MD_SUPPORT_TICKETS_CONTAINER {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 1000px;
}

.USER_PREVIEW_TICKET_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  height: max-content;
  max-height: 800px;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 20px;
  background: var(--background);
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
  transition: all 0.1s ease-in;
  overflow: auto;
}

.USER_SUBMIT_TICKET_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 400px;
  height: max-content;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 20px;
  background: var(--background);
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
  transition: all 0.1s ease-in;
}
.USER_SUBMIT_TICKET_LABLE {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 95%;
  height: 60px;
  padding-inline: 1em;
  margin-bottom: 1em;
  color: var(--font-color);
}

.USER_SUBMIT_TICKET_LABLE > h3 {
  font-family: sans-serif;
}

.USER_SUBMIT_TICKET_HEADER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  min-height: 80px;
  height: max-content;
}

.SUPPORT_INPUT_FIELD_CONTAINER {
  display: flex;
  padding: 0.5em;
  width: 90%;
}
.SUPPORT_FORM_INPUT_FIELD {
  color: var(--font-color) !important;
}
.SUPPORT_FORM_INPUT_FIELD_LABEL {
  color: var(--field-lable) !important;
}

.CONTROL_DASHBOARD_SUBMIT_TICKET_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.USER_SUBMIT_TICKET_BODY {
  display: flex;
  width: 94%;
  height: 200px;
  min-height: max-content;
  margin-top: 1em;
}
.USER_SUBMIT_TICKET_FOOTER {
  display: flex;
  width: 94%;
  height: 60px;
  min-height: 50px;
}

@media only screen and (max-width: 600px) {
  .USER_PREVIEW_TICKET_MAIN_CONTAINER {
    width: 90%;
  }
  .USER_SUBMIT_TICKET_HEADER {
    flex-direction: column;
  }
  .USER_SUBMIT_TICKET_LABLE {
    width: 90%;
  }
}
