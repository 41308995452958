.CONTROL_DASHBOARD_CONTENT_ALERTS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.CONTROL_DASHBOARD_CONTENT_ALERT_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 100px;
  /* border: 1px solid #c5cdd3; */
  border-radius: 10px;
  margin-top: 20px;
}
