/*  PARAMETERS  */
.DASHBOARD_CONTROLLER_PARAMETERS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1300px;
  min-height: max-content;
}

.DASHBOARD_CONTROLLER_PARAMETERS_WRAPPER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1300px;
  min-height: max-content;
  overflow: auto;
}

.DASHBOARD_CONTROLLER_PARAMETERS_HEADER_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  width: 250px;
  height: 50px;
  margin-top: 3em;
  margin-bottom: 2em;
  margin-right: auto;
  margin-left: 1em;
  border-radius: 50px;
  background: linear-gradient(
    145deg,
    var(--header-background1),
    var(--header-background2)
  );
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
}

.DASHBOARD_CONTROLLER_PARAMETERS_HEADER_CONTAINER h2 {
  color: var(--font-color);
  font-family: sans-serif;
}

@media only screen and (max-width: 600px) {
  .DASHBOARD_CONTROLLER_PARAMETERS_HEADER_CONTAINER {
    margin-left: auto;
  }
  .DASHBOARD_CONTROLLER_PARAMETERS_HEADER_CONTAINER > span {
    font-size: 0.7rem;
  }

  .DASHBOARD_CONTROLLER_PARAMETERS_MAIN_CONTAINER {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .DASHBOARD_CONTROLLER_PARAMETERS_WRAPPER {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-inline: 0em;
    padding-inline: 0em;
    overflow: hidden;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .DASHBOARD_CONTROLLER_PARAMETERS_MAIN_CONTAINER {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
}
