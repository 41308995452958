.REGISTER_CONTROLLER_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 94vh;
  background-color: #dae1e7;
}

.REGISTER_CONTROLLER_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.REGISTER_CONTROLLER_ICON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #162e4c;
  height: 200px;
  width: 200px;
}
.REGISTER_CONTROLLER_HEADER_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
}

.REGISTER_CONTROLLER_HEADER_CONTAINER > h2 {
  text-align: center;
}

.VERIFICATION_FEEDBACK_MESSAGE {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 0.5em;
  border-radius: 10px;
}
.REGISTER_CONTROLLER_FORM_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
}
.REGISTER_CONTROLLER_BUTTON_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
