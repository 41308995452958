.USER_GUIDE_ITEM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
  margin: 1em;
  min-height: 200px;
  width: 90%;
  height: max-content;
  /* border: 1px solid #000; */
}

.USER_GUIDE_ITEM_HEADER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.USER_GUIDE_ITEM_HEADER_TITLE > h2 {
  font-family: sans-serif;
  color: var(--font-color);
}

.USER_GUIDE_ITEM_HEADER_PIC_ITEM {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  font-size: 4rem;
  color: var(--font-color);
  border-radius: 20px;
  background: linear-gradient(145deg, var(--background1), var(--background2));
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
  transition: all 0.5s ease-in-out;
}

.USER_GUIDE_ITEM_DES {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 800px;
  margin-right: auto;
}

.USER_GUIDE_ITEM_DES > p {
  font-family: Roboto;
  color: var(--secondary-font-color);
}

@media only screen and (max-width: 600px) {
  .USER_GUIDE_ITEM_HEADER_PIC_ITEM {
    width: 100px;
    height: 100px;
    font-size: 3rem;
  }
}
