.SIGNUP_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 94vh;
  background-color: #dae1e7;
}

.SIGNUP_FORM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  padding-top: 20px;
}
.SIGNUP_ICON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #162e4c;
  height: 150px;
  width: 150px;
}
.SIGNUP_FORM_HEADER {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  font-family: sans-serif;
}
.SIGNUP_FORM_INPUT_FIELD_CONTAINER {
  padding: 5px;
  margin-bottom: 5px;
  width: 350px;
  height: 60px;
}
.SIGNUP_INPUT_FIELD {
  color: #162447 !important;
  border-radius: 10px !important;
  /* height: 50px; */
}
.SIGNUP_INPUT_FIELD_LABEL {
  color: #162e4c !important;
  font-family: monospace !important;
  font-size: 0.85rem !important;
}
.SIGNUP_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #ffffff inset !important;
  -webkit-text-fill-color: #363a3b;
}
.form-control {
  width: 350px;
}
#signUpHeader {
  color: rgb(131, 131, 131) !important;
  font-family: Rubik;
  font-weight: bold;
  font-size: 1.1rem;
}

#signUpHeader {
  color: rgb(215, 215, 219);
  margin-bottom: 20px;
}

#passwordMatchAlert {
  color: rgb(235, 83, 78);
  font-stretch: wider;
  font-weight: bold;
}

#wrongCredentials {
  color: rgb(235, 83, 78);
  font-stretch: wider;
  font-weight: bold;
}

.form-group {
  /* border: 0.1rem solid rgb(219, 143, 120); */
  border-radius: 10px;
}
.form-signup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.SIGNUP_FORM_ICON {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SIGNUP_BUTTON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 20px;
  height: 45px;
  width: 200px !important;
  border-radius: 10px;
  background: #152c48;
  /* border: 1px solid #162e4c65; */
  /* box-shadow: 10px 10px 20px #b9bfc4, -10px -10px 20px #fbffff;*/
  transition: all 0.5s ease-in-out;
}
.SIGNUP_BUTTON_CONTAINER h6 {
  font-size: 1.1rem;
  color: #dddddd;
  font-family: sans-serif;
}

.SIGNUP_BUTTON_CONTAINER:hover {
  cursor: pointer;
  background: #15345a;
  transform: scale(1.05);
}

.BACK_BUTTON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 20px;
  height: 45px;
  width: 200px !important;
  border-radius: 10px;
  background: #1e3f66;
  transition: all 0.5s ease-in-out;
}

.BACK_BUTTON_CONTAINER h6 {
  font-size: 1.1rem;
  color: #dddddd;
  font-family: sans-serif;
}

.BACK_BUTTON_CONTAINER:hover {
  cursor: pointer;
  background: #15345a;
  transform: scale(1.05);
}
