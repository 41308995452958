.SUPPORT_PREVIOUS_TICKETS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  color: var(--font-color);
}

.SUPPORT_PREVIOUS_TICKETS_CONTENT {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.SUPPORT_PREVIOUS_TICKETS_LIST {
  display: flex;
  width: 60%;
  max-height: 200px;
  overflow: auto;
  transition: all 1s ease-in-out;
}

.SUPPORT_PREVIOUS_TICKETS_GRAPH {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
  /* background-color: aqua; */
}

.SUPPORT_PREVIOUS_TICKET_DATA {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  width: 90%;
  height: 50px;
  margin-left: 3em;
  /* border: 1px dashed #dddddd; */
  color: var(--font-color);
  transition: all 1s ease-in-out;
}

@media only screen and (max-width: 600px) {
  .SUPPORT_PREVIOUS_TICKETS_CONTENT {
    flex-direction: column;
  }
  .SUPPORT_PREVIOUS_TICKETS_LIST {
    width: 100%;
  }
}
