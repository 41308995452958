.HOME_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100%;
  height: max-content;
}

.HOME_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 100%;
  height: max-content;
}

.SIGNUP_ERROR_MESSAGE {
  text-align: center;
  /* height: 50px; */
  font-family: Roboto;
}

.HOME_USER_REVIEW_IMAGE {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.HOME_USER_REVIEW_OPTIONS {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
}

.HOME_USER_REVIEW_HEADER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
}

.HOME_USER_REVIEW_HEADER > h1 {
  font-family: sans-serif;
  color: #f0f5fad1;
}

.HOME_LOGIN_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  height: 50px;
  width: 200px !important;
  margin-bottom: 10px;
  margin-top: 20px;
  background-color: #d4e2ee;
  transition: all 0.5s ease-in-out;
}
.HOME_LOGIN_BUTTON h6 {
  font-size: 1rem;
  font-family: sans-serif;
  color: #1c2028;
}
.HOME_LOGIN_BUTTON:hover {
  cursor: pointer;
  background-color: #b1c6ec;
}

.HOME_SIGNUP_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  height: 50px;
  width: 200px !important;
  margin-bottom: 10px;
  margin-top: 20px;
  background-color: #b8f0b8;
  transition: all 0.5s ease-in-out;
}
.HOME_SIGNUP_BUTTON h6 {
  font-size: 1rem;
  font-family: sans-serif;
  color: #1c2028;
}
.HOME_SIGNUP_BUTTON:hover {
  cursor: pointer;
  background-color: #42cb85;
}

@media only screen and (max-width: 600px) {
  .HOME_CONTAINER {
    flex-direction: column;
  }
  .HOME_USER_REVIEW_OPTIONS {
    width: 95%;
  }
  .HOME_USER_REVIEW_HEADER {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
  }
  .HOME_USER_REVIEW_HEADER > h1 {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .HOME_CONTAINER {
    width: 100%;
  }

  .HOME_USER_REVIEW_IMAGE {
    width: 40%;
    padding-top: 2em;
  }

  .HOME_USER_REVIEW_OPTIONS {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .HOME_USER_REVIEW_HEADER {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
  }
  .HOME_USER_REVIEW_HEADER > h1 {
    font-size: 1.7rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .HOME_CONTAINER {
    width: 90%;
  }

  .HOME_USER_REVIEW_IMAGE {
    width: 40%;
    padding-top: 2em;
  }

  .HOME_USER_REVIEW_OPTIONS {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .HOME_USER_REVIEW_HEADER {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
  }
  .HOME_USER_REVIEW_HEADER > h1 {
    font-size: 1.7rem;
  }
}
