.DASHBOARD_GRAPHS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1300px;
  min-height: max-content;
  /* margin-top: 1em; */
  margin-bottom: 50px;
  transition: all 1s ease-in-out;
}

.DASHBOARD_GRAPHS_WRAPPER {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  margin-inline: auto;
  /* padding-inline: 1em; */
  padding-top: 1.5em;
  padding-bottom: 1em;
  overflow: auto;
}

.DASHBOARD_GRAPHS_HEADER_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  width: 250px;
  height: 50px;
  margin-top: 1.5em;
  margin-bottom: 2em;
  margin-left: 1em;
  margin-right: auto;
  border-radius: 50px;
  border-radius: 50px;
  background: linear-gradient(
    145deg,
    var(--header-background1),
    var(--header-background2)
  );
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
}

.DASHBOARD_GRAPHS_HEADER_CONTAINER h2 {
  color: var(--font-color);
  font-family: sans-serif;
}
.DASHBOARD_GRAPHS_HEADER_CONTAINER span {
  color: var(--font-color);
  font-family: sans-serif;
  font-size: 0.9rem;
  line-height: 5px;
}

.DASHBOARD_GRAPHS_SCROLL_INDICATOR {
  display: none;
}

@media only screen and (max-width: 380px) {
  .DASHBOARD_GRAPHS_SCROLL_INDICATOR {
    display: flex;
    position: absolute;
    height: 225px;
    width: 30px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    top: 17%;
    right: -10px;
    opacity: 0.5;
    z-index: 0;
    box-shadow: 10px -10px 40px var(--background1);
    background-color: var(--font-color);
    transition: all 0.5s ease-in-out;
  }
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 1.5rem;
  }
  .DASHBOARD_GRAPHS_HEADER_CONTAINER {
    margin-left: auto;
  }
  .DASHBOARD_GRAPHS_HEADER_CONTAINER > span {
    font-size: 0.7rem;
  }

  .DASHBOARD_GRAPHS_MAIN_CONTAINER {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-width: 90%;
    min-height: 300px;
    overflow: auto;
  }
  .DASHBOARD_GRAPHS_WRAPPER {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    min-width: 90%;
    min-height: 300px;
    overflow: auto;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .DASHBOARD_GRAPHS_WRAPPER {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    min-width: 90%;
    min-height: 350px;
    overflow: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .DASHBOARD_GRAPHS_WRAPPER {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    min-width: 90%;
    min-height: 350px;
    overflow: auto;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .DASHBOARD_GRAPHS_WRAPPER {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    min-width: 90%;
    min-height: 350px;
    overflow: auto;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
}
