.CONTROL_DASHBOARD_CONTENT_DEVICES_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.CONTROL_DASHBOARD_CONTENT_FILTER_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  min-height: 100px;
  border: 1px solid #334756;
  border-radius: 10px;
  margin-top: 20px;
}

.CONTROL_DASHBOARD_CONTENT_USER_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  min-height: 200px;
  /* border: 1px solid #334756; */
  border-radius: 10px;
  margin-top: 20px;
  background-color: #eeeeee;
  overflow: auto;
}

.CONTROL_DASHBOARD_CONTENT_USER_HEADER_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
}
.CONTROL_DASHBOARD_CONTENT_USER_HEADER_CONTAINER h3 {
  font-family: sans-serif;
  color: #222831;
}
.CONTROL_DASHBOARD_CONTENT_USER_BODY_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  height: 120px;
}

.CONTROL_DASHBOARD_CONTENT_PARAMETERS_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 300px;
  border: 1px solid #334756;
  border-radius: 10px;
  margin-top: 20px;
}

.CONTROL_DASHBOARD_CONTENT_GRAPHS_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 300px;
  border: 1px solid #334756;
  border-radius: 10px;
  margin-top: 20px;
}

.FILTER_DEVICE_FORM_INPUT_FIELD {
  color: #222831 !important;
  border-radius: 10px !important;
}

.FILTER_DEVICE_FORM_INPUT_FIELD input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #1e272e inset !important;
  -webkit-text-fill-color: #a2aaad;
}

.FILTER_DEVICE_FORM_INPUT_FIELD_LABEL {
  color: #373b42 !important;
  font-family: monospace !important;
  font-size: 0.85rem !important;
}

.CONTROLLER_FILTER_FORM_INPUT_FIELD_CONTAINER {
  padding: 5px;
  margin-bottom: 5px;
  max-width: 50%;
  min-width: 200px;
  width: 250px;
}

.CONTROL_DASHBOARD_CONTENT_FILTER_FIELDS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 60%;
}

.CONTROL_DASHBOARD_CONTENT_FILTER_BUTTON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}
