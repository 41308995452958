.CONTROL_DASHBOARD_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: #dddddd;
}

.CONTROL_DASHBOARD_TOOLBAR_BRAND_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  min-width: max-content;
}

.CONTROL_DASHBOARD_TOOLBAR_BRAND_CONTAINER h1 {
  font-size: larger;
  font-family: sans-serif;
}

.CONTROL_DASHBOARD_CONTENT_MAIN_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  height: 100%;
  background-color: #dddddd;
}

.CONTROL_DASHBOARD_CONTENT_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  min-width: 1000px;
  min-height: 1000px;
  height: 100%;
  margin-top: 100px;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .CONTROL_DASHBOARD_CONTENT_MAIN_CONTAINER {
    width: 400px;
    overflow: auto;
  }
  .CONTROL_DASHBOARD_CONTENT_CONTAINER {
    width: 400px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .CONTROL_DASHBOARD_CONTENT_MAIN_CONTAINER {
    width: 600px;
    overflow: auto;
  }
  .CONTROL_DASHBOARD_CONTENT_CONTAINER {
    width: 600px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .CONTROL_DASHBOARD_CONTENT_MAIN_CONTAINER {
    width: 800px;
    overflow: auto;
  }
  .CONTROL_DASHBOARD_CONTENT_CONTAINER {
    width: 800px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .CONTROL_DASHBOARD_CONTENT_MAIN_CONTAINER {
    width: 1000px;
    overflow: auto;
  }
  .CONTROL_DASHBOARD_CONTENT_CONTAINER {
    width: 1000px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  .CONTROL_DASHBOARD_CONTENT_MAIN_CONTAINER {
    width: 1200px;
    overflow: auto;
  }
  .CONTROL_DASHBOARD_CONTENT_CONTAINER {
    width: 1200px;
  }
}
