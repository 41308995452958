.SETTINGS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: max-content;
  min-height: 98vh;
  background-color: var(--background);
  padding-top: 2em;
}
