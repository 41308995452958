.USER_GUIDE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 94vh;
  height: max-content;
  overflow: hidden;
  background-color: var(--background);
}

.USER_GUIDE_HEADER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding-top: 1em;
}
.USER_GUIDE_HEADER > h1 {
  font-family: sans-serif;
  color: var(--font-color);
}

.USER_GUIDE_ITEMS_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
}
