.ANALYTICS_CAROUSEL_CARD {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--background3);
  width: 500px;
  min-width: 280px;
  max-width: 800px;
  height: 95%;
  padding: 0 2rem 2rem 2rem;
  border-radius: 10px;
}

.ANALYTICS_CAROUSEL_CARD h2 {
  font-family: sans-serif;
  color: var(--font-color);
  margin: 0;
  margin: 1rem;
}

.ANALYTICS_CAROUSEL_CARD p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.ANALYTICS_CAROUSEL_CARD .btnn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 380px) {
}

@media only screen and (max-width: 600px) {
  .ANALYTICS_CAROUSEL_CARD {
    width: 300px;
  }
  .ANALYTICS_CAROUSEL_CARD > h2 {
    font-size: 1.2rem;
  }
}
