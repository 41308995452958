.FORGOT_PASSWORD_PAGE_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 150px;
  height: 100vh;
  background-color: #dae1e7;
}
.FORGOT_PASSWORD_FORM {
  display: flex;
  flex-direction: column;
  width: 350px;
}
.FORGOT_PASSWORD_LOADING_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  font-size: 6rem;
  color: #27496d;
}
.FORGOT_PASSWORD_INPUT_FIELD {
  color: #27496d !important;
}
.FORGOT_PASSWORD_ERROR_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
}
.FORGOT_PASSWORD_ERROR_CONTAINER p {
  font-size: 1rem;
  text-align: center;
  font-family: Roboto;
  font-weight: 600;
  color: #27496d;
}
.FORGOT_PASSWORD_FORM_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 200px !important;
  border-radius: 25px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px;
  background: #152c48;
  /* border: 1px solid #162e4c65; */
  /* box-shadow: 10px 10px 20px #b9bfc4, -10px -10px 20px #fbffff;*/
  transition: all 0.5s ease-in-out;
}
.FORGOT_PASSWORD_FORM_BUTTON h6 {
  color: #dae1e7;
  font-family: sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
}
.FORGOT_PASSWORD_FORM_BUTTON:hover {
  cursor: pointer;
}
