.CONFIGS_SETTINGS_POWER_LIMITS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100px;
  min-height: 100px;
  margin-top: 1em;
}

.CONFIGS_SETTINGS_POWER_LIMITS_HEADER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 320px;
  max-width: 500px;
  color: var(--font-color);
}
.CONFIGS_SETTINGS_POWER_LIMITS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CONFIGS_SETTINGS_POWER_LIMITS_VALUES_CONTAINER {
  display: flex;
  flex-direction: row;
}

.CONFIGS_SETTINGS_POWER_LIMITS_VALUE {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  border: 1px solid #1f4068;
  border-radius: 10px;
  background-color: var(--temp-value-bg);
  margin-block: 0.5em;
  color: #d4d4f1;
  font-family: sans-serif;
  font-size: 2rem;
  font-weight: bold;
  margin: 0.3em;
}
.CONFIGS_SETTINGS_POWER_LIMITS_VALUE > p {
  font-family: Roboto;
}
.CONFIGS_SETTINGS_POWER_LIMITS_VALUE > span {
  font-family: monospace;
  font-size: 1.3rem;
  margin-left: 1em;
}

.CONFIGS_SETTINGS_POWER_LIMITS_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;
  width: 50px;
  height: 50px;
  font-size: 2rem;
  color: #f05454;
  border-radius: 50px;
  background: linear-gradient(145deg, var(--background1), var(--background2));
  box-shadow: 5px 5px 10px var(--shade-1), -5px -5px 20px var(--shade-2);
  transition: all 1s ease-in-out;
}

.CONFIGS_SETTINGS_POWER_LIMITS_BUTTON:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .CONFIGS_SETTINGS_POWER_LIMITS_VALUES_CONTAINER {
    flex-direction: column;
  }
  .CONFIGS_SETTINGS_POWER_LIMITS_VALUE {
    width: 120px;
    height: 40px;
  }
  .CONFIGS_SETTINGS_POWER_LIMITS_VALUE > p {
    font-family: Roboto;
    font-size: 1.4rem;
  }
  .CONFIGS_SETTINGS_POWER_LIMITS_VALUE > span {
    font-family: monospace;
    font-size: 1.2rem;
    margin-left: 1em;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .CONFIGS_SETTINGS_POWER_LIMITS_VALUES_CONTAINER {
    flex-direction: row;
  }
}
