.DASHBOARD_CONTROLLER_PARAMETERS_CONTAINER {
  display: grid;
  grid-template-columns: repeat(5, 200px);
  gap: 3em;
  align-items: flex-end;
  justify-content: flex-start;
  /* margin-top: 50px; */
  width: 90%;
  height: 420px;
  max-width: inherit;
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 2em;
}

.DASHBOARD_CONTROLLER_PARAMETER_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 200px;
  height: 200px;
  border-radius: 20px;
  background: linear-gradient(145deg, var(--background1), var(--background2));
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
  transition: all 0.5s ease-in-out;
}

.DASHBOARD_CONTROLLER_PARAMETER_ICON {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  height: 25%;
  font-size: 1.5rem;
  color: #ff4c29;
}
.DASHBOARD_CONTROLLER_PARAMETER_INFO {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
  width: 100%;
}
.DASHBOARD_CONTROLLER_PARAMETER_INFO > span {
  font-family: sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  color: #0c7b93;
}
.DASHBOARD_CONTROLLER_PARAMETER_INDICATOR {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
  width: 100%;
  color: var(--font-color);
  font-size: 2.5rem;
}
.DASHBOARD_CONTROLLER_PARAMETER_INDICATOR > p {
  font-family: sans-serif;
  font-size: 1.3rem;
  color: #0c7b93;
}
.DASHBOARD_CONTROLLER_PARAMETER_INDICATOR > span {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 2rem;
  color: var(--font-color);
}

.CONTROLLER_HEATING_INDICATOR {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 30%;
  max-width: 50px;
  max-height: 50px;
}

#heating_indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

#heating_indicator > span {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: sans-serif;
  transition: all 2s ease-in-out;
  animation: heating 1.5s infinite;
  color: #c86c02;
}

@keyframes heating {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.95;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.95;
  }
  100% {
    opacity: 1;
  }
}

.DASHBOARD_CONTROLLER_PARAMETER_POWER_BUTTON {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
  width: 100%;
  color: #29c7ac;
  font-size: 5rem;
  transition: all 1s ease-in-out;
  animation: runorder 2s infinite;
}

@keyframes runorder {
  0% {
    color: #c86c02;
  }
  25% {
    color: #e1ad03;
  }
  50% {
    color: #c86c02;
  }
  75% {
    color: #e1ad03;
  }
  100% {
    color: #c86c02;
  }
}

.DASHBOARD_CONTROLLER_PARAMETER_POWER_BUTTON:hover {
  cursor: pointer;
  filter: brightness(70%);
}

.PARAMETERS_WARNING_MODAL {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  min-height: 200px;
  background-color: #dddada;
  border-radius: 20px;
  padding: 1em;
}
.PARAMETERS_WARNING_MODAL > h2 {
  font-family: sans-serif;
  color: #f05454;
}

.PARAMETERS_WARNING_MODAL > p {
  text-align: center;
  font-family: sans-serif;
  color: var(--font-color);
}

.PARAMETERS_WARNING_MODAL_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 25px;
  background-color: #1a2f4b;
  margin: 1em;
}
.PARAMETERS_WARNING_MODAL_BUTTON:hover {
  cursor: pointer;
  background-color: #244877;
}
.PARAMETERS_WARNING_MODAL_BUTTON > span {
  color: #dddada;
  font-family: Arial;
}

@media only screen and (max-width: 600px) {
  .DASHBOARD_CONTROLLER_PARAMETER_INFO > span {
    font-size: 1rem;
  }

  .DASHBOARD_CONTROLLER_PARAMETER_INDICATOR > span {
    font-size: 1.7rem;
  }

  .DASHBOARD_CONTROLLER_PARAMETER_INDICATOR > p {
    font-size: 0.9rem;
  }

  .DASHBOARD_CONTROLLER_PARAMETERS_CONTAINER {
    grid-template-columns: repeat(2, 170px);
    gap: 1em;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: max-content;
    padding: 0.5em;
    margin-top: 1.5em;
  }
  .DASHBOARD_CONTROLLER_PARAMETER_CONTAINER {
    width: 170px;
    height: 170px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .DASHBOARD_CONTROLLER_PARAMETERS_CONTAINER {
    grid-template-columns: repeat(3, 170px);
    gap: 2em;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-top: 50px;
  }
  .DASHBOARD_CONTROLLER_PARAMETER_CONTAINER {
    width: 170px;
    height: 170px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .DASHBOARD_CONTROLLER_PARAMETERS_CONTAINER {
    grid-template-columns: repeat(3, 200px);
    align-items: center;
    justify-content: center;
    /* margin-top: 50px; */
    width: 100%;
    padding: 1em;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .DASHBOARD_CONTROLLER_PARAMETERS_CONTAINER {
    grid-template-columns: repeat(4, 200px);
    align-items: center;
    justify-content: center;
    /* margin-top: 50px; */
    width: 100%;
    padding: 1em;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .DASHBOARD_CONTROLLER_PARAMETERS_CONTAINER {
    grid-template-columns: repeat(4, 200px);
    align-items: center;
    justify-content: center;
    /* margin-top: 50px; */
    width: 100%;
    padding: 1em;
  }
}
