.LOGS_HEADER {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  max-width: 750px;
  min-height: 50px;
  height: 110px;
  font-family: sans-serif;
  color: var(--font-color);
  transition: all 0.5s ease-in-out;
}
.LOGS_NAVIGATION_HEADER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  max-width: 750px;
  min-height: 50px;
  height: 50px;
  transition: all 0.5s ease-in-out;
}
.LOGS_NAVIGATION_HEADER_ITEM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
  border-radius: 25px;
  background: linear-gradient(145deg, var(--background3), var(--background3));
  box-shadow: 5px 5px 10px var(--shade-1), -5px -5px 10px var(--background1);
  transition: all 0.5s ease-in-out;
}

.LOGS_NAVIGATION_HEADER_ITEM:hover {
  cursor: pointer;
}

.LOGS_NAVIGATION_HEADER_ITEM > span {
  font-family: sans-serif;
  font-weight: bold;
  color: var(--font-color);
}
.LOGS_NAVIGATION_HEADER_ITEM > p {
  font-family: sans-serif;
  color: var(--secondary-font-color);
  height: 10px;
}

.LOGS_DATE_PICKERS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
