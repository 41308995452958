body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #162e4c;
  /* width: 100vw; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #dddddd;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bbbbbb;
}

#dark {
  --background: #162e4c;
  --background1: #162e4c;
  --background2: #183151;
  --background3: #0e1c2f;
  --background4: #455673;
  --user-reply-bg: #334756;
  --assistance-reply-bg: #053f5e;
  --header-background1: #111f30;
  --header-background2: #040e1c;
  --shade-1: #132741;
  --shade-2: #193557;
  --font-color: #bbbbbb;
  --secondary-font-color: #adadad;
  --temp-value-bg: #111f30;
  --field-lable: #dddddd;
}

#light {
  --background: #dddddd;
  --background1: #ececec;
  --background2: #c7c7c7;
  --background3: #ededed;
  --background4: #ececec;
  --user-reply-bg: #dddddd;
  --assistance-reply-bg: #bbe1fa;
  --header-background1: #ececec;
  --header-background2: #c7c7c7;
  --shade-1: #a7a7a7;
  --shade-2: #fefefe;
  --font-color: #17293e;
  --secondary-font-color: #1c2d43;
  --temp-value-bg: #162e4c;
  --field-lable: #182c43;
}
