.SETTING_ITEM_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  min-height: 150px;
  width: 90%;
  height: max-content;
  margin: 1.5em;

  border-radius: 20px;
  background: var(--background);
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
  transition: all 0.1s ease-in;
}
.SETTING_ITEM_TITLE {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  font-family: sans-serif;
  color: var(--font-color);
}
.SETTING_ITEM_ACTION {
  display: flex;
  align-items: center;
  justify-content: center;
}
.SETTING_ITEM_DESCREPTION {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  font-family: Roboto;
  color: var(--secondary-font-color);
}
