.DASHBOARD_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 3em;
  background-color: var(--background);
}

.DASHBOARD_MAIN_HEADER {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.DASHBOARD_MAIN_HEADER > p {
  color: var(--font-color);
  font-family: sans-serif;
  font-size: 0.9rem;
  line-height: 5px;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 1.5rem;
  }

  .DASHBOARD_CONTROL_MAIN_CONTAINER {
    flex-direction: column;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .DASHBOARD_CONTROL_MAIN_CONTAINER {
    flex-direction: column;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
}
