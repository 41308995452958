#dark {
  --background1: #1b3a63;
  --background2: #142944;
  --shade-1: #132741;
  --shade-2: #193557;
  --font-color: #bbbbbb;
}

#light {
  --background1: #ececec;
  --background2: #c7c7c7;
  --shade-1: #bcbcbc;
  --shade-2: #fefefe;
  --font-color: #111f30;
}

.DASHBOARD_GRAPHS_WRAPPER_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;
  transition: all 1s ease-in-out;
}

@keyframes slide {
  0% {
    margin-left: 0em;
  }
  20% {
    margin-left: -2em;
  }
  40% {
    margin-left: -4em;
  }
  60% {
    margin-left: -6em;
  }
  80% {
    margin-left: -4em;
  }
  100% {
    margin-left: 0em;
  }
}

.DASHBOARD_GRAPHS_CONTAINER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  transition: all 1s ease-in-out;
}

.DASHBOARD_GRAPH_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CHART_WATER_MARK_CONTAINER {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  z-index: 1;
  opacity: 0.6;
  background-color: var(--background);
}

.CHART_WATER_MARK_CONTAINER > p {
  color: var(--font-color);
  font-family: sans-serif;
}

@media only screen and (max-width: 600px) {
  .DASHBOARD_GRAPHS_WRAPPER_CONTAINER {
    display: inline-block;
    width: max-content;
    padding-right: 2em;
  }
  .DASHBOARD_GRAPH_CONTAINER {
    width: 100vw;
  }
  .DASHBOARD_GRAPHS_WRAPPER_CONTAINER {
    animation: slide 2s;
    animation-iteration-count: 2;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .DASHBOARD_GRAPHS_WRAPPER_CONTAINER {
    display: inline-block;
    width: max-content;
    padding-right: 2em;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
}
