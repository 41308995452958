.CONTROL_DASHBOARD_GRAPHS_MAIN_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.CONTROL_DASHBOARD_GRAPHS_WRAPPER_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
.CONTROL_DASHBOARD_GRAPHS_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  min-width: max-content;
  height: 100%;
  padding-inline: 1em;
}
