.VACATION_MODE_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.VACATION_MODE_BUTTON_CONTAINER {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  min-height: 70px;
}

.VACATION_MODE_BUTTON {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;
  width: 70px;
  height: 70px;
  font-size: 2.5rem;
  color: #f05454;
  border-radius: 50px;
  background: linear-gradient(145deg, var(--background1), var(--background2));
  box-shadow: 5px 5px 15px var(--shade-1), -5px -5px 15px var(--shade-2);
  transition: all 0.5s ease-in-out;
}

.VACATION_MODE_BUTTON:hover {
  cursor: pointer;
  background: linear-gradient(145deg, var(--background1), var(--background2));
  box-shadow: 5px 5px 20px var(--shade-1), -5px -5px 20px var(--shade-2);
}

.VACATION_MODE_BUTTON:active {
  cursor: pointer;
  box-shadow: none;
}

.VACATION_MODE_INFO_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  min-height: 100px;
}

.VACATION_MODE_INFO_CONTAINER > h1 {
  font-family: sans-serif;
  color: var(--font-color);
}

.VACATION_MODE_INFO_CONTAINER > span {
  font-family: sans-serif;
}

.VACATION_MODE_INFO_CONTAINER > p {
  font-family: sans-serif;
  color: var(--font-color);
  width: 250px;
  text-align: center;
}
