.NOTIFICATIONS_MAIN_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: max-content;
  min-height: 98vh;
  padding-top: 1em;
  background-color: var(--background);
}

.NOTIFICATION_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5em;
  width: 90%;
  max-width: 700px;
  min-height: 200px;
  height: max-content;
  border-radius: 20px;
  background: var(--background);
  box-shadow: 10px 10px 20px var(--shade-1), -10px -10px 20px var(--shade-2);
  transition: all 0.1s ease-in;
}

.NOTIFICATION_HEADER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  min-height: 50px;
  height: 50px;
  color: var(--font-color);
}

.NOTIFICATION_HEADER > h3 {
  color: var(--field-lable);
  font-family: sans-serif;
}

.NOTIFICATION_BODY {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  min-height: 100px;
  height: 100px;
}
.NOTIFICATION_BODY > span {
  color: var(--font-color);
  font-family: Roboto;
  font-size: 1rem;
}
.NOTIFICATION_BODY > p {
  color: var(--secondary-font-color);
}

.NOTIFICATION_FOOTER {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 92%;
  min-height: 50px;
  height: 50px;
  color: #1764c3;
  font-size: 0.8rem;
  font-weight: bold;
}

.NO_NOTIFICATION_CONTAINER {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3em;
  margin-bottom: 2em;
  width: 90%;
  max-width: 1100px;
  min-height: 100px;
  height: max-content;
  border-radius: 20px;
  background: var(--background);
  box-shadow: 15px 15px 30px var(--shade-1), -15px -15px 30px var(--shade-2);
  transition: all 0.1s ease-in;
}

.NO_NOTIFICATION_CONTAINER > h2 {
  font-family: sans-serif;
  color: var(--font-color);
}
